import React, {useState} from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const HeaderStyle = styled.header`
    padding: 1em; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    @media (min-width: 769px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 1.4em 4em;  
    }
`;

const NavStyle = styled.nav`
    display: flex;
    justify-content: space-between;
    gap: 1.2em;
    a{
        text-transform: uppercase;
        text-decoration: 4px underline #BFBFBF;
        transition-duration: 1s;
        font-size: .9em;
        &:hover{
            text-decoration: 4px underline #77b843;
            transition-duration: 1s;
        }
    }
    @media (max-width: 769px) {
        display: none;
    }
    
`;

const MenuMobile = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

const NavMobile = styled.nav`
  background-color: rgba( 255,255,255, .98);
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  div{
    display: grid;
    padding: 1em 1em;
    text-align: center;
    a{
        margin: 6px 0 !important;
        text-transform: uppercase;
        text-decoration: 4px underline #d9d9d9;
        transition-duration: 1s;
        font-size: 1em;
        &:hover{
            text-decoration: 4px underline #77b843;
            transition-duration: 1s;
        }
    }
  }
  hr{
        width: 100%; 
        height: 4px;
        background: rgb(255,255,255);
        background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(217,217,217,0.923406862745098) 48%, rgba(119,184,67,1) 100%);
    }
`;

const Header = () => {

    const [ menu, setMenu ] = useState(false);


    return ( 
        <HeaderStyle>
            <Link
                to="/"
            >
                <StaticImage
                src="../images/logo.png"
                width={220}
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Inteligencia Solar"
                />   
            </Link>  
            <MenuMobile>
                <a onClick={() => setMenu(true) }  href='#!'>
                    <StaticImage
                        src="../images/menu.png"
                        width={40}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Menú"
                        style={{ marginLeft: '10px'}}
                    />  
                </a>
            </MenuMobile> 
            <NavStyle>
                <Link
                    to="/paneles-solares/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Paneles Solares
                </Link>
                <Link
                    to="/ingenieria-especializada/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Ingenieria Especializada
                </Link>
                <Link
                    to="/albercas/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Albercas
                </Link>
                <Link
                    to="/calentadores-solares/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Calentadores
                </Link>
                <Link
                    to="/calderas/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Calderas
                </Link>
                <Link
                    to="/iluminacion-led/"
                    activeStyle={{ color: "#77b843" }}
                >
                    Iluminación LED
                </Link>                
                <Link
                    to="/#contacto"
                    activeStyle={{ color: "#77b843" }}
                >
                    Contacto
                </Link>
            </NavStyle>
            {
                menu ?
                    <NavMobile>
                        <div>
                            <div style={{ margin: '0 auto', textAlign: 'center', justifyContent: 'center', maxHeight: '80px', maxWidth: '80px'}}>
                                <Link
                                    to="/"
                                >
                                    <StaticImage
                                    src="../images/favicon.png"
                                    width={100}
                                    quality={90}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="whatsapp"
                                    /> 
                                </Link>
                            </div>     
                            <br></br>                                                       
                            <hr/>
                            <Link
                                to="/paneles-solares/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Paneles Solares</a>                                
                            </Link>
                            <Link
                                to="/ingenieria-especializada/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Ingenieria Especializada</a>                                
                            </Link>
                            <Link
                                to="/albercas/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Albercas</a>                                
                            </Link>
                            <Link
                                to="/calentadores-solares/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Calentadores</a>
                            </Link>
                            <Link
                                to="/calderas/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Calderas</a>
                            </Link>
                            <Link
                                to="/iluminacion-led/"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Iluminación Led</a>
                            </Link> 
                            <br></br>  
                            <hr/>             
                            <Link
                                to="/#contacto"
                                activeStyle={{ color: "#77b843" }}
                            >
                                <a onClick={() => setMenu(false) }  href='#!'>Contacto</a>
                            </Link>                            
                            <Link to='mailto:info@inteligenciasolar.com.mx' target='_blank' rel="noreferrer">info@inteligenciasolar.com.mx</Link>
                            <section>
                                <Link to='tel:5563830657' target='_blank' rel="noreferrer" >55 6383 0657</Link> , 
                                <Link to='tel:5533300008' target='_blank' rel="noreferrer" > 55 3330 0008</Link>
                            </section>
                            <div style={{ margin: '0 auto', textAlign: 'center', justifyContent: 'center', maxHeight: '20px', maxWidth: '20px'}}>
                                <Link to='https://wa.me/525578584764' target='_blank' rel="noreferrer">
                                    <StaticImage
                                    src="../images/whatsapp.png"
                                    width={30}
                                    quality={90}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="whatsapp"
                                    /> 
                                </Link>
                            </div>
                        </div>
                    </NavMobile>
                : null
            }
        </HeaderStyle>
     );
}
 
export default Header;