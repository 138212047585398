import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const FooterStyle = styled.footer`
    display: grid;
    padding: 1em;
    border-top: 2px solid #77b843;
    margin-top: 2em; 
    gap: 1em;
    text-align:center;
    p{
        text-align:center;
        margin: 0;

    }
    @media (min-width: 769px) {
        text-align:left;
        max-width: 1620px;
        margin: 0 auto;
        padding: 2em 4em; 
        grid-template-columns: repeat(3,1fr);  
        gap: 0;       
    }
`;

const Footer = () => {
    return ( 
        <FooterStyle>

            <div>
                <Link
                    to='https://www.instagram.com/inteligenciasolar/'  target='blank'                  
                >
                    <StaticImage
                        src="../images/instagram.png"
                        width={30}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Menú"
                        style={{ marginLeft: '10px'}}
                    />  
                </Link>
                <Link
                    to='https://www.facebook.com/inteligenciasolar/' target='blank'                    
                >
                    <StaticImage
                        src="../images/facebook.png"
                        width={30}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Menú"
                        style={{ marginLeft: '10px'}}
                    /> 
                </Link> 
                <Link
                    to='https://twitter.com/intelsolarmx' target='blank'                    
                >
                    <StaticImage
                        src="../images/twitter.png"
                        width={30}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Menú"
                        style={{ marginLeft: '10px'}}
                    /> 
                </Link> 
                <Link
                    to='https://www.youtube.com/channel/UCKoRr9c8m_AMLYFipEc6LhA' target='blank'                    
                >
                    <StaticImage
                        src="../images/youtube.png"
                        width={35}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Menú"
                        style={{ marginLeft: '10px'}}
                    /> 
                </Link>                                
            </div>   
            <p>Inteligencia Solar México ©{new Date().getFullYear()}</p>

        </FooterStyle>
     );
}
 
export default Footer;