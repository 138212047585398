import React from 'react';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import {BtnIntro} from '../components/ui'
import Layout from '../components/layout';

const SectionStyle = styled.section`
    height: 80vh;
    padding: 1em; 
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        text-align: center;
        h3{
            font-size: 1.5rem;
            line-height: 1.4;
        }
    }
    @media (min-width: 769px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 2em;  
    }
`;


const Gracias = () => {
    return ( 
        <Layout>
            <SectionStyle>
                <div>
                    <StaticImage
                        src="../images/logo.png"
                        width={280}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Inteligencia Solar"
                    />
                    <br></br><br></br>
                    <h3>¡Agradecemos tu interés,<br></br>pronto estaremos en contacto!</h3>
                    <Link
                        to='/'                    
                    >
                        <BtnIntro>
                            Volver al inicio
                        </BtnIntro>
                    </Link> 
                </div>
            </SectionStyle>
        </Layout>
     );
}
 
export default Gracias;