import styled from 'styled-components'

export const BtnIntro = styled.a`
    background-color: transparent;
    color: #77b843;
    border: 1px solid #77b843;
    border-radius: 4px;
    padding: .2em 1.5em; 
    box-shadow: 0px 3px 6px #00000029;
    font-weight: 600;
    font-size: 1em;
    text-transform: uppercase;
    &:hover{
        background-color: #ffffff;
        color: #77b843;
    }
    @media (min-width: 769px) {
        font-size: 1.2em;
        padding: .5em 1.5em; 
    }
`;

export const InputStyle = styled.input`
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 2px 5px;
`;

export const TextareaStyle = styled.textarea`
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 2px 5px;
`;

export const CheckGeneral = styled.input`
    margin: 4px 0;
    width: 15px;
    height: 15px;    
`;

export const CheckLabel = styled.label`
    font-size: 1rem;
    margin: 2px 0 2px .5em;
`;

export const SendStyle = styled.input`
    border: 1px solid #77b843;
    border-radius: 4px;
    font-weight: 600;
    font-size: 1em;    
    color: #77b843;
    background-color: #ffffff !important;
`;